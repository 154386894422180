import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { StyledIconTitle } from './styles';
import { Text } from '../Text';

interface IIconTitle {
  className?: string;
  style?: any;
  id?: string;
  icon?: any;
  iconAlt?: string;
  title?: string;
}

export const IconTitle = ({
  className,
  style,
  id,
  icon,
  iconAlt,
  title,
}: IIconTitle) => (
  <StyledIconTitle id={id} className={className} style={style}>
    {icon ? (
      <div className="icon-title__icon">
        <GatsbyImage image={icon} alt={iconAlt} className="icon-title__image" />
      </div>
    ) : null}
    <div className="icon-title__title">
      <Text noRhythem type="title4" tag="h4" color="yellow_1">
        {title}
      </Text>
    </div>
  </StyledIconTitle>
);
