const config = {
  siteTitle: 'Domusnetwork.io',
  siteTitleAlt: 'domusnetwork', // Site title.
  siteRss: '/rss.xml',
  siteUrl: 'https://domusnetwork.io', // Domain of your website without.
  siteDescription: 'Domusnetwork.io site.',
  copyright: 'Copyright © Domusnetwork.io 2018. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  newsletter: {
    description:
      'we promise to deliver only valuable carfuly crafted content that we our selfs find interesting, we will never waste your time with ads or promotional content',
    heading: 'Newsletter',
    buttonText: 'Sign up',
  },
  disqusShortname: 'domusnetwork-io', // Disqus shortname.
  mailChimpEndpoint:
    'https://domusnetwork.us17.list-manage.com/subscribe/post?u=1c5ea2191fadf6ac8ae9ac96f&amp;id=d4ec3a81c8&amp;f_id=00be85e0f0',
  iconPath: 'static/icon.png',
  backgroundColor: '#ffffff',
  themeColor: '#00d1b2',
  googleAnalyticsTrackingId: 'G-NPBNN6L6DR',
  googleTagManagerId: 'GTM-KTXJD5T',
};

module.exports = config;
