import React from 'react';
import { Helmet } from 'react-helmet-async';
import config from '../../../meta/config';

interface ISeo {
  title: string;
  metaTitle: string;
  metaDesc: string;
  cover: any;
  isHomePage?: boolean;
  isArticlePage?: boolean;
  slug: string;
  templateKey: string;
}

export const Seo = ({
  title,
  metaTitle,
  metaDesc,
  cover,
  isHomePage = false,
  isArticlePage = false,
  slug,
  templateKey,
}: ISeo) => {
  const postURL = config.siteUrl + slug;
  const image = `${config.siteUrl}${cover}`;
  const blogURL = `${config.siteUrl}/blog`;
  const schemaOrgJSONLD = [
    {
      '@context': `http://schema.org`,
      '@type': `WebSite`,
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : ``,
    },
    {
      '@context': `http://schema.org`,
      '@type': `BreadcrumbList`,
      itemListElement: [
        {
          '@type': `ListItem`,
          position: 1,
          item: {
            image,
            '@id': postURL,
            name: title,
          },
        },
      ],
    },
    ...(isHomePage
      ? [
          {
            '@context': `https://schema.org`,
            '@type': `Organization`,
            url: config.siteUrl,
          },
        ]
      : []),
    ...(isArticlePage
      ? [
          {
            metaDesc,
            '@context': `http://schema.org`,
            '@type': `BlogPosting`,
            url: blogURL,
            name: title,
            alternateName: config.siteTitleAlt ? config.siteTitleAlt : ``,
            headline: title,
            image: {
              '@type': `ImageObject`,
              url: image,
            },
          },
        ]
      : []),
  ];
  return (
    <Helmet>
      <title>{metaTitle}</title>
      {/* General tags */}
      <meta name="description" content={metaDesc} />
      <meta name="image" content={cover} />
      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {/* OpenGraph tags */}
      <meta property="og:url" content={postURL} />
      <meta property="og:type" content={templateKey} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta
        property="fb:app_id"
        content={config.siteFBAppID ? config.siteFBAppID : ``}
      />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:creator"
        content={config.userTwitter ? config.userTwitter : ``}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDesc} />
      <meta name="twitter:image" content={image} />
      <link rel="canonical" href={postURL} />
    </Helmet>
  );
};
