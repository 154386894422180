import styled from 'styled-components';
import { rem } from 'polished';

export const StyledIconTitle = styled.div`
  display: flex;
  margin-bottom: ${rem(`12px`)};
  .icon-title__icon {
    flex: 0 1 auto;
    width: ${rem(`48px`)};
    height: ${rem(`48px`)};
    margin-right: ${rem(`16px`)};
  }
  .icon-title__title {
    flex: 0 1 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;
