/* eslint-disable react/no-danger */
import React from 'react';
import { remark } from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import externalLinks from 'remark-external-links';
import { StyledMdContent } from './styles';

interface IMdContent {
  className?: string;
  style?: any;
  content?: any;
  tag?: any;
}

export const MdContent = ({
  className,
  style,
  content,
  tag = `div`,
}: IMdContent) => {
  const mdContent = remark()
    .use(recommended)
    .use(remarkHtml)
    .use(externalLinks, { target: `_blank`, rel: [`nofollow`] })
    .processSync(content)
    .toString();

  return (
    <StyledMdContent as={tag} className={className} style={style}>
      <div
        dangerouslySetInnerHTML={{
          __html: mdContent,
        }}
      />
    </StyledMdContent>
  );
};
