import React from 'react';
import { Content } from '../Content';
import { IconTitle } from '../IconTitle';
import { MdContent } from '../MdContent';
import { Text } from '../Text';
import { StyledCallToAction } from './styles';

interface ICallToAction {
  className?: any;
  hookId?: any;
  icon?: any;
  title?: any;
  body?: any;
  maxWidth?: string;
}

export const CallToAction = ({
  className,
  hookId,
  icon,
  title,
  body,
  maxWidth = `90%`,
}: ICallToAction) => {
  return (
    <StyledCallToAction className={className}>
      <IconTitle
        id={hookId}
        icon={icon}
        iconAlt={`Icon for ${title}`}
        title={title}
      />
      <Content
        restrictWidth
        setMaxWidth={maxWidth}
        disableSubtitlePadding
        className="content"
      >
        <Text type="body1" color="gray_3">
          <MdContent content={body} />
        </Text>
      </Content>
    </StyledCallToAction>
  );
};
