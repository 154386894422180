import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledCallToAction {
  theme: any;
}

export const StyledCallToAction = styled.div<IStyledCallToAction>`
  .content {
    margin-bottom: ${rem(`48px`)};
    padding-left: 0;
    ${({ theme }) => theme.breakpoints.phone`
      width: 100%;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    `}
    ${({ theme }) => theme.breakpoints.phablet`
      :not(.content--no-padding) {
        padding-left: ${rem(`64px`)};
      }
    `}
  }
`;
